import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import "./Ranking.scss";
import wanted from "../../assets/images/wanted.png";
import avatar1 from "../../assets/images/avatar/avatar1.jpg";
import avatar2 from "../../assets/images/avatar/avatar2.jpg";
import avatar3 from "../../assets/images/avatar/avatar3.jpg";
import avatar4 from "../../assets/images/avatar/avatar4.jpg";
import avatar5 from "../../assets/images/avatar/avatar5.jpg";
import avatar6 from "../../assets/images/avatar/avatar6.jpg";
import avatar7 from "../../assets/images/avatar/avatar7.jpg";
import avatar8 from "../../assets/images/avatar/avatar8.jpg";
import avatar9 from "../../assets/images/avatar/avatar9.jpg";
import avatar10 from "../../assets/images/avatar/avatar10.jpg";
import avatar11 from "../../assets/images/avatar/avatar11.jpg";
import avatar12 from "../../assets/images/avatar/avatar12.jpg";
import avatar13 from "../../assets/images/avatar/avatar13.jpg";
import avatar14 from "../../assets/images/avatar/avatar14.jpg";
import avatar15 from "../../assets/images/avatar/avatar15.jpg";
import avatar16 from "../../assets/images/avatar/avatar16.jpg";
import avatar17 from "../../assets/images/avatar/avatar17.jpg";
import avatar18 from "../../assets/images/avatar/avatar18.jpg";
import avatar19 from "../../assets/images/avatar/avatar19.jpg";
import avatar20 from "../../assets/images/avatar/avatar20.jpg";
import avatar21 from "../../assets/images/avatar/avatar21.jpg";
import avatar22 from "../../assets/images/avatar/avatar22.jpg";
import avatar23 from "../../assets/images/avatar/avatar23.jpg";
import avatar24 from "../../assets/images/avatar/avatar24.jpg";
// import avatar25 from "../../assets/images/avatar/avatar25.jpg";
// import avatar26 from "../../assets/images/avatar/avatar26.jpg";
// import avatar27 from "../../assets/images/avatar/avatar27.jpg";
// import avatar28 from "../../assets/images/avatar/avatar28.jpg";
// import avatar29 from "../../assets/images/avatar/avatar29.jpg";
import { fetchRanking } from "../../redux/actions/ranking";
import { getBalance } from "../../redux/actions/session";
import Menu from "../../components/menuDesktop/MenuDesktop";
import MobileMenu from "../../components/menuMobile/MenuMobile";
import { logout } from "../../redux/actions/session";
import WhatsAppButton from "../../components/whatsAppButton/WhatsAppButton";
import PowerButton from "../../components/powerButton/PowerButton";
import DiscordButton from "../../components/discordButton/DiscordButton";

const Ranking = () => {
  const dispatch = useDispatch();
  const ranking = useSelector((state) => state.ranking);

  useEffect(() => {
    dispatch(fetchRanking());
    dispatch(getBalance());
  }, [dispatch]);

  const getAvatar = (index) => {
    const rest = index % 24;
    if (rest === 0) return avatar1;
    if (rest === 1) return avatar2;
    if (rest === 2) return avatar3;
    if (rest === 3) return avatar4;
    if (rest === 4) return avatar5;
    if (rest === 5) return avatar6;
    if (rest === 6) return avatar7;
    if (rest === 7) return avatar8;
    if (rest === 8) return avatar9;
    if (rest === 9) return avatar10;
    if (rest === 10) return avatar11;
    if (rest === 11) return avatar12;
    if (rest === 12) return avatar13;
    if (rest === 13) return avatar14;
    if (rest === 14) return avatar15;
    if (rest === 15) return avatar16;
    if (rest === 16) return avatar17;
    if (rest === 17) return avatar18;
    if (rest === 18) return avatar19;
    if (rest === 19) return avatar20;
    if (rest === 20) return avatar21;
    if (rest === 21) return avatar22;
    if (rest === 22) return avatar23;
    if (rest === 23) return avatar24;
    // if (rest === 24) return avatar25;
    // if (rest === 25) return avatar26;
    // if (rest === 26) return avatar27;
    // if (rest === 27) return avatar28;
    // if (rest === 28) return avatar29;
  };

  const getMedal = (index) => {
    if (index === 1) {
      return { medal: "🏆", prize: "Prêmio será de R$750" };
    }

    if (index === 2) {
      return { medal: "🥈", prize: "Prêmio será de R$250" };
    }

    if (index === 3) {
      return { medal: "🥉", prize: "Prêmio será de R$100" };
    }

    if (index === 4) {
      return { medal: "🎖️", prize: "Prêmio será de R$75" };
    }

    if (index === 5) {
      return { medal: "🎖️", prize: "Prêmio será de R$50" };
    }

    if (index === 6) {
      return { medal: "🃏", prize: "Prêmio será de R$25" };
    }

    if (index === 7) {
      return { medal: "🃏", prize: "Prêmio será de R$25" };
    }

    if (index === 8) {
      return { medal: "🃏", prize: "Prêmio será de R$25" };
    }

    if (index === 9) {
      return { medal: "🃏", prize: "Prêmio será de R$25" };
    }

    if (index === 10) {
      return { medal: "🃏", prize: "Prêmio será de R$25" };
    }
  };

  const getPlayer = ({ nickname, points }, index) => {
    const avatar = getAvatar(index);
    const medalData = getMedal(index + 1);
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <img src={avatar} />
        </td>
        <td>
          {/* <div style={{ marginBottom: "5px" }}>
            {medalData && medalData.medal + " " + medalData.prize}
          </div> */}
          {nickname}
        </td>
        <td>{points}</td>
        <td className="avatar mobile">
          {/* <div style={{ marginBottom: "5px" }}>
            {medalData && medalData.medal + " " + medalData.prize}
          </div> */}
          {index + 1}- {nickname}
          <img src={avatar} />
          {points} Pontos
        </td>
      </tr>
    );
  };

  return (
    <>
      <Menu />
      <div className="wanted">
        <header>
          <img src={wanted} className="text" style={{ margin: "10px auto" }} />
        </header>
        {/* <div className="ranking-headline">
          <p>Premiações no dia 07/02, para os top 10 colocados.</p>
        </div> */}
        <table className="content-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Avatar</th>
              <th>Nick Name</th>
              <th>Pontos</th>
              <th className="mobile">Rank</th>
            </tr>
          </thead>
          <tbody>
            {ranking &&
              ranking.players &&
              ranking.players.map((player, index) => getPlayer(player, index))}
          </tbody>
        </table>
      </div>
      <WhatsAppButton></WhatsAppButton>
      <MobileMenu />
    </>
  );
};

export default Ranking;
